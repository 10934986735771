:root {
  --space-cadet: #171738ff;
  --blue-gray: #6b9ac4ff;
  --tiffany-blue: #97d8c4ff;
  --antiflash-white: #eff2f1ff;
  --xanthous: #f4b942ff;
}

body {
  margin: 0;
  font-family: "Courier New", Courier, monospace;
  background: var(--space-cadet);
  color: var(--antiflash-white);
  overflow: hidden;
}

.landing-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding: 20px;
}

.header {
  text-align: center;
}

.logo {
  max-width: 200px;
  margin-bottom: 20px;
}

.title {
  font-size: 2rem;
  color: var(--antiflash-white);
}

.content {
  text-align: center;
}

.description {
  font-size: 1.2rem;
  margin-top: 10px;
  color: var(--xanthous);
}

.email {
  margin-top: 15px;
  font-size: 1rem;
  color: var(--tiffany-blue);
}

.footer {
  position: absolute;
  bottom: 10px;
  left: 0;
  width: 100%; /* Ensure footer spans the entire width */
  display: flex;
  justify-content: space-between; /* Position child elements to opposite ends */
  align-items: center;
  padding: 0 20px;
}

.footer-email {
  color: var(--tiffany-blue);
  text-decoration: none;
  font-size: 0.9rem;
  margin-right: 40px;
}

.footer-email:hover {
  color: var(--xanthous); /* Change email color on hover */
  text-decoration: underline; /* Underline the email text on hover */
}


.mailto-button {
  padding: 10px 20px;
  background-color: var(--tiffany-blue);
  color: var(--space-cadet);
  font-family: "Courier New", Courier, monospace;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.mailto-button:hover {
  background-color: var(--blue-gray);
}